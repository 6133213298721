<template>
  <dragablemodal
    :name="'showInfoCardModal'"
    ref="dragablemodal"
    :height="'auto'"
    :width="900"
  >
    <header class="modal-header">
      <h4 class="modal-title">
        <span v-if="infocardData != null">
          {{ infocardData.name }} <small>{{ infocardData.refId }}</small>
        </span>
        <span v-else> Loading... </span>
      </h4>
    </header>
    <div class="modals-content">
      <page-loader :loading="!infocardData || isFetchingInfocard">
        <div class="infocard-wrap" v-if="infocardData != null">
          <div>
            <b-row class="head">
              <b-col cols="3" class="p-0">
                <div class="cover-wrap">
                  <img
                    v-if="infocardData.coverPath"
                    :src="`${apiBaseUrl()}/images/${infocardData.coverPath}`"
                  />
                  <img v-else src="@/assets/images/container-no-image.jpg" />
                </div>
              </b-col>
              <b-col cols="9" class="p-0">
                <infocard-position-map :infocard="infocardData" />
              </b-col>
            </b-row>
            <template v-if="infocardData.type == 'ContainerInfoCard'">
              <b-row @mousedown.stop>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{
                      T("Web.Generic.Infocards.Collector")
                    }}:</span
                  ></b-col
                >
                <b-col sm="9">{{ infocardData.collectorName }}</b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{ T("Web.Generic.Infocards.Placement") }}:</span
                  ></b-col
                >
                <b-col sm="9">{{ infocardData.placement }}</b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{
                      T("Web.Generic.Ordering.ReceivingFacilities")
                    }}:</span
                  ></b-col
                >
                <b-col sm="9">{{ infocardData.recevingFacilityName }}</b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold">{{
                    T("Web.Generic.Infocards.ReceiverRef")
                  }}</span></b-col
                >
                <b-col sm="9">{{ infocardData.receiverRef }}</b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{
                      T("Web.Generic.Infocards.Pickupdays")
                    }}:</span
                  ></b-col
                >
                <b-col sm="9">
                  <weekday-picker
                    :disabled="true"
                    v-model="infocardData.pickupDays"
                  />
                </b-col>
                <b-col cols="12">
                  <h6 class="content-area-title">
                    {{ T("Web.Generic.Infocards.ContentHeadline") }}
                  </h6>
                </b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{ T("Web.Generic.Infocards.WasteCategory") }}:</span
                  ></b-col
                >
                <b-col sm="9">{{
                  getWasteCategory(infocardData.wasteCategory).text
                }}</b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{ T("Web.Generic.Infocards.Fraction") }}:</span
                  ></b-col
                >
                <b-col sm="9">{{ infocardData.wasteFractionName }}</b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{ T("Web.Generic.Infocards.EWCCode") }}:</span
                  ></b-col
                >
                <b-col sm="9"
                  >{{ infocardData.ewcCode }} |
                  {{
                    infocardData.ewcAlias != null
                      ? infocardData.ewcAlias
                      : infocardData.ewcName
                  }}</b-col
                >
                <b-col cols="12">
                  <h6 class="content-area-title">
                    {{ T("Web.Generic.Infocards.ContainerHeadline") }}
                  </h6>
                </b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{
                      T("Web.Generic.Infocards.ContainerType")
                    }}:</span
                  ></b-col
                >
                <b-col sm="9">{{ infocardData.containerTypeName }} </b-col>
                <b-col sm="3"
                  ><span class="font-weight-bold"
                    >{{ T("Web.Generic.Infocards.Size") }}:</span
                  ></b-col
                >
                <b-col sm="9"
                  ><span v-if="infocardData.containerSize > 0"
                    >{{ infocardData.containerSize || toCurrency }}
                    {{
                      getContainerSizeUnit(infocardData.containerSizeUnit).text
                    }}
                  </span></b-col
                >
              </b-row>
            </template>
          </div>
        </div>
      </page-loader>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleModalHidden"
        >
          {{ T("Web.Generic.Close") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";
import { BButton, BRow, BCol } from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import { mapActions, mapGetters } from "vuex";
import InfocardPositionMap from "./InfocardListing/maps/InfocardPositionMap.vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
export default {
  components: {
    dragablemodal,
    BButton,
    PageLoader,
    BRow,
    BCol,
    InfocardPositionMap,
    WeekdayPicker,
  },
  data() {
    return {
      infocardData: null,
    };
  },
  computed: {
    ...mapGetters({
      infocardById: "infocards/byId",
      isFetchingInfocard: "infocards/isLoading",
    }),
  },
  methods: {
    ...mapActions({ fetchInfocard: "infocards/fetchSingle" }),
    async open(id) {
      this.$refs.dragablemodal.show();
      await this.fetchInfocard({ id: id });
      this.infocardData = this.infocardById(id);
    },
    handleModalHidden() {
      this.infocardData = null;
      this.$refs.dragablemodal.hide();
    },
  },
};
</script>


<style lang="scss" scoped>
.infocard-wrap {
  .head {
    margin-top: -1rem;
    margin-bottom: 20px;
    border-bottom: 2px solid #f4f4fb;
    .cover-wrap {
      img {
        width: 100%;
      }
    }
  }
  .content-area-title {
    color: #b9b9c3;
    padding: 10px 0px;
    margin: 10px 0px;
  }
  .col-sm-3 {
    margin-bottom: 5px;
  }
}
</style>