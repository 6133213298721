<template>
  <gmaps-map :options="mapOptions" @mousedown.stop>
    <infocard-map-marker :infocard="infocard" />
  </gmaps-map>
</template>

<script>
import { gmapsMap, gmapsMarker } from "x5-gmaps";
import InfocardMapMarker from "./InfocardMapMarker.vue";

export default {
  components: {
    gmapsMap,
    gmapsMarker,
    InfocardMapMarker,
  },
  props: {
    infocard: {
      required: true,
    },
  },
  computed: {
    mapOptions() {
      return {
        zoom: 18,
        center: new google.maps.LatLng(this.lat, this.lng),
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: true,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
      };
    },

    lng() {
      return this.infocard.longitude;
    },
    lat() {
      return this.infocard.latitude;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 100%;
}
</style>
