<template>
  <div>
    <b-modal
      id="booststrapmodal"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
    >
      <b-row
        v-for="listItem in accountUsers"
        :key="listItem.id"
        class="listItems rowitem offset-top"
      >
        <span @click="toggleSelected(listItem)"
          >{{ listItem.alias }}

          <input :checked="selectedUser == listItem" type="checkbox" />
        </span>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            :disabled="selectedUser == undefined"
            variant="primary"
            class="float-right mr-2"
            @click="handleSubmit"
          >
            {{ T("Web.Generic.Send") }}
          </b-button>
          <b-button
            variant="danger"
            class="float-right mr-2"
            @click="handleCancel"
          >
            {{ T("Web.Generic.Cancel") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BModal,
    BRow,
    BButton,
  },
  created() {},
  data() {
    return {
      visible: false,
      selectedUser: undefined,
    };
  },
  computed: {
    ...mapGetters({
      accountUsers: "accountUsers/list",
    }),
  },
  methods: {
    ...mapActions({
      fetchAccountUsers: "accountUsers/fetchList",
    }),
    toggleSelected(item) {
      if (this.selectedUser == item) this.selectedUser = undefined;
      else this.selectedUser = item;
    },
    async open() {
      await this.fetchAccountUsers();
      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.visible = false;
      this.selectedUser = undefined;
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
    handleSubmit() {
      this.resolvePromise(this.selectedUser);
      this.handleModalHidden();
    },
  },
  setup() {},
};
</script>
<style lang="scss"  scoped>
.listItems {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  > span {
    cursor: pointer;
    padding: 10px;
    display: flex;
    background-color: #f3f2f7;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
  }
}
</style>