<template>
  <div>
    <b-modal
      id="booststrapmodal"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
      size="lg"
    >
      <b-container fluid>
        <b-row class="head">
          <b-col cols="3" class="p-0">
            <div class="cover-wrap mr-1">
              <img
                v-if="infocard.infoCardNameCoverPath"
                :src="`${apiBaseUrl()}/images/${
                  infocard.infoCardNameCoverPath
                }`"
              />
              <img v-else src="@/assets/images/container-no-image.jpg" />
            </div>
          </b-col>
          <b-col cols="9" class="p-0">
            <infocard-loaction-map
              :lng="infocard.longitude"
              :lat="infocard.latitude"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.Name") }}</b-col>
          <b-col sm="6">{{ infocard.infoCardName }}</b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.Category") }} </b-col>
          <b-col sm="6">{{ wasteCategory[infocard.infocardCategory] }}</b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.WasteFraction") }} </b-col>
          <b-col sm="6">{{ infocard.infocardWasteFraction }}</b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.ContainerType") }} </b-col>
          <b-col sm="6">{{ infocard.infocardContainerType }}</b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.Size") }} </b-col>
          <b-col sm="6">{{
            infocard.infocardSize + " " + sizeUnits[infocard.infocardSizeUnit]
          }}</b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.ReceivingFacilitie") }} </b-col>
          <b-col sm="6">{{ infocard.infocardReceivingFacilitie }}</b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.EwcCategory") }} </b-col>
          <b-col sm="6">{{ infocard.infocardEwcCatrgory }}</b-col>
        </b-row>
        <b-row class="mb-1 border-bottom-light" cols="2">
          <b-col sm="6">{{ $t("Generic.EwcName") }} </b-col>
          <b-col sm="6">{{ infocard.infocardEwcName }}</b-col>
        </b-row>
        <b-row v-if="infocard.latitude != null && infocard.latitude > 0">
          <b-col cols="12" sm="12"> </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right mr-2"
            @click="handleCancel"
          >
            {{ $t("Generic.Okay") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BContainer, BCol, BModal, BRow, BButton } from "bootstrap-vue";
import InfocardLoactionMap from "@/components/google-maps/InfocardLoactionMap.vue";

export default {
  components: {
    BModal,
    BRow,
    BButton,
    BContainer,
    BCol,
    InfocardLoactionMap,
  },
  created() {},
  data() {
    return {
      visible: false,
      infocard: {},

      sizeUnits: {
        Cubic: "m3",
        Litre: "L",
      },

      wasteCategory: {
        None: this.$t("Generic.WasteCategoryNone"),
        Rezycl: this.$t("Generic.WasteCategoryRezycl"),
        Reuse: this.$t("Generic.WasteCategoryReuse"),
        Combostion: this.$t("Generic.WasteCategoryCombostion"),
        Special: this.$t("Generic.WasteCategorySpecial"),
        Landfill: this.$t("Generic.WasteCategoryLandfill"),
      },
    };
  },
  computed: {},
  methods: {
    async open(infoCard) {
      this.infocard = infoCard;
      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.visible = false;
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
  setup() {},
};
</script>
<style lang="scss"  scoped>
.listItems {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  > span {
    cursor: pointer;
    padding: 10px;
    display: flex;
    background-color: #f3f2f7;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
  }
}
.head {
  margin-bottom: 20px;
  .cover-wrap {
    img {
      width: 100%;
    }
  }
}
</style>