<template>
  <b-container>
    <page-loader :loading="fetching"> sss </page-loader>
    <!--
    <page-loader :loading="fetching">
      <div class="order-inbox">
        <order-image-modal ref="orderImageModal" />
        <supplier-order-info-card-info ref="supplierOrderInfoCardInfo" />
        <sup-supplier-selector ref="supSupplierSelector" />
        <confirm-modal ref="confirmModal" />

        <div class="side-bar">
          <div class="side-bar-menu">
            <h3 class="title"></h3>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :class="{ active: activeCategory == 'INBOX' }"
                @click="activeCategory = 'INBOX'"
              >
                <feather-icon icon="InboxIcon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">Inbox</span>
                <b-badge pill class="float-right" variant="light-primary">
                  {{ newOrders.length }}
                </b-badge>
              </b-list-group-item>
              <b-list-group-item
                :class="{ active: activeCategory == 'CONFIRMED' }"
                @click="activeCategory = 'CONFIRMED'"
              >
                <feather-icon icon="CheckSquareIcon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">Confirmed</span>
                <b-badge pill class="float-right" variant="light-primary">
                  {{ confirmedOrders.length }}
                </b-badge>
              </b-list-group-item>
              <b-list-group-item
                :class="{ active: activeCategory == 'CLOSED' }"
                @click="activeCategory = 'CLOSED'"
              >
                <feather-icon icon="ClipboardIcon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">Closed</span>
                <b-badge pill class="float-right" variant="light-primary">
                  {{ emptiedOrders.length }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
        <vue-perfect-scrollbar
          :settings="{
            maxScrollbarLength: 150,
          }"
          class="orders-list"
        >
          <h3
            class="text-muted d-flex justify-content-center mt-5"
            v-if="ordersToShow.length == 0"
          >
            Ingen odre at vise
          </h3>
          <ul>
            <li
              v-for="order in ordersToShow"
              :key="order.id"
              :class="{ 'bg-light-primary': order.forwarded }"
            >
              <div class="left pe-50">
                <b-avatar
                  v-if="!order.infoCardNameCoverPath"
                  src="@/assets/images/container-no-image.jpg"
                />
                <b-avatar
                  v-else
                  :src="`${apiBaseUrl()}/images/${order.infoCardNameCoverPath}`"
                />
              </div>
              <div class="right">
                <div class="order-details">
                  <div>
                    <h5 class="mb-25">
                      {{ order.infoCardName }}
                      <small>({{ order.refId }})</small>
                    </h5>
                    <h6 class="font-weight-bold">
                      <feather-icon icon="UserIcon" /> {{ order.customerName }}
                    </h6>
                    <span class="details-text">
                      <feather-icon icon="MapPinIcon" />
                      {{ order.locationName }},
                      {{ order.locationAddress }}</span
                    >
                    <span class="details-text">
                      <feather-icon icon="BoxIcon" />
                      {{ order.infocardWasteFraction }},
                      <small v-if="order.infocardSizeUnit != 'Unknown'">
                        {{ order.infocardContainerType }}
                        {{ order.infocardSize | toCurrency }}
                        {{
                          getContainerSizeUnit(order.infocardSizeUnit).value
                        }}</small
                      ></span
                    >
                    <span
                      class="details-text text-warning"
                      v-if="order.receiverRef || order.comment"
                    >
                      <feather-icon icon="MessageCircleIcon" />
                      {{ order.receiverRef }} {{ order.comment }}</span
                    >
                    <span
                      class="details-text text-primary font-weight-bold"
                      v-if="role == 'Supplier' && order.forwarded != null"
                    >
                      <feather-icon icon="CornerDownRightIcon" />
                      <span>
                        {{
                          $t("CreateCollectorModal.ForwardToText") +
                          order.forwarded
                        }}</span
                      ></span
                    >
                  </div>
                  <div>
                    <div class="order-date text-right">
                      {{ format_datetime(order.createdDate) }}
                    </div>
                    <div class="actions">
                      <icon-badge
                        :bgColor="'#f3f2f7'"
                        :txtColor="'#6e6b7b'"
                        @click="openInfoCard(order)"
                        icon="InfoIcon"
                      />

                      <icon-badge
                        :bgColor="
                          order.images && order.images.length > 0
                            ? '#407e3d'
                            : '#f3f2f7'
                        "
                        :txtColor="
                          order.images && order.images.length > 0
                            ? '#fff'
                            : '#6e6b7b'
                        "
                        @click="openImageModel(order)"
                        icon="ImageIcon"
                        v-b-tooltip.hover.top="
                          $t('CreateCollectorModal.ImageBtnText')
                        "
                      />

                      <icon-badge
                        v-if="
                          order.confirmDate.indexOf('0001-01-01T00:00:00') >
                            -1 && !order.forwarded
                        "
                        @click="approveOrder(order.id)"
                        icon="CheckIcon"
                        v-b-tooltip.hover.top="
                          $t('CreateCollectorModal.ApproveBtnText')
                        "
                      />
                      <icon-badge
                        v-else-if="!order.emptied"
                        @click="emptyOrder(order.id)"
                        icon="CheckIcon"
                        v-b-tooltip.hover.top="
                          $t('CreateCollectorModal.EmptiedBtnText')
                        "
                      />

                      <icon-badge
                        v-if="
                          role != 'SupSupplier' &&
                          order.forwarded == null &&
                          accountUsers != undefined &&
                          accountUsers.length > 0
                        "
                        bgColor="#f3f2f7"
                        txtColor="#6e6b7b"
                        hoverbgColor="#407e3d"
                        @click="forwardOrder(order.id)"
                        icon="ArrowRightIcon"
                        v-b-tooltip.hover.top="
                          $t('CreateCollectorModal.ForwardBtnText')
                        "
                      />
                      <icon-badge
                        v-else-if="
                          role != 'SupSupplier' && order.forwarded != null
                        "
                        bgColor="#f3f2f7"
                        txtColor="#6e6b7b"
                        hoverbgColor="#ccc"
                        icon="ArrowRightIcon"
                        v-b-tooltip.hover.top="
                          $t('CreateCollectorModal.ForwardedBtnText')
                        "
                      />
                      <icon-badge
                        v-if="
                          role != 'Supplier' ||
                          (role == 'Supplier' && order.forwarded != null)
                        "
                        bgColor="#f3f2f7"
                        txtColor="#6e6b7b"
                        hoverbgColor="#407e3d"
                        @click="pullBackForword(order.id)"
                        :icon="role != 'SupSupplier' ? 'RotateCcwIcon' : 'XIcon'"
                        v-b-tooltip.hover.top="
                          role == 'Supplier'
                            ? $t('CreateCollectorModal.UndoForwordBtnText')
                            : $t('CreateCollectorModal.DeclineOrder')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </page-loader>
      -->
  </b-container>
</template>

<script>
import {
  BCard,
  BSpinner,
  BRow,
  BCardTitle,
  BTable,
  BTabs,
  BTab,
  BAvatar,
  BFormCheckbox,
  BCol,
  BButton,
  BBadge,
  BContainer,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import IconBadge from "@/components/IconBadge.vue";
import authHelper from "@/auth";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import { VBTooltip } from "bootstrap-vue";
import OrderImageModal from "@/components/modals/infoCards/OrderImageModal.vue";
import ResourceService from "@/services/base/resource.service";
import OrdersService from "@/services/collection/orders-service";
import SupSupplierSelector from "@/views/suppliers/components/SupSupplierSelector.vue";
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import ShowInfocardModal from "@/app/views/background-data/infocards/components/ShowInfocardModal.vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SupplierOrderInfoCardInfo from "./components/SupplierOrderInfoCardInfo.vue";

export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCardTitle,
    BTable,
    BTabs,
    BTab,
    BAvatar,
    BFormCheckbox,
    FeatherIcon,
    BCol,
    BButton,
    BBadge,
    IconBadge,
    VBTooltip,
    OrderImageModal,
    SupSupplierSelector,
    ConfirmModal,
    ShowInfocardModal,
    BContainer,
    PageLoader,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    SupplierOrderInfoCardInfo,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async mounted() {
    try {
      this.fetching = true;
      this.service = new ResourceService("suppliers");
      //  this.orderService = new OrdersService();
      // await this.fetchOrders();
      //if (this.role == "Supplier") await this.fetchAccountUsers();
      //window.location.href = "/suppliers/orders/"
      this.$router.push({ name: "suppliers.orders.list" });
    } finally {
      this.fetching = false;
    }
  },
  data() {
    return {
      active: "New",
      sortBy: "createdDate",
      baseUrl: process.env.VUE_APP_APIURL,
      sortDesc: true,
      service: null,
      orderService: null,
      orders: [],
      accountId: authHelper.getAccountId(),
      fetching: false,
      activeCategory: "INBOX",
    };
  },
  methods: {
    ...mapActions({
      fetchAccountUsers: "accountUsers/fetchList",
    }),
    async openInfoCard(data) {
      this.$refs.showInfocardModal.open(data.infocardId);
      //await this.$refs.supplierOrderInfoCardInfo.open(data);
    },
    async fetchOrders() {
      var response = await this.service.get("/orders");
      this.orders = response.data.data;
    },
    async openImageModel(order) {
      await this.$refs.orderImageModal.open(order);
    },
    async approveOrder(orderId) {
      await this.orderService.approveCollection(orderId);

      var response = await this.service.get("/orders");
      this.orders = response.data.data;
    },
    async emptyOrder(orderId) {
      await this.orderService.markasEmptyForCollectorOrder(
        orderId,
        this.accountId
      );

      var response = await this.service.get("/orders");
      this.orders = response.data.data;
    },
    async forwardOrder(orderId) {
      let result = await this.$refs.supSupplierSelector.open();
      if (result == undefined || !result) return;

      await this.service.post(`/orders/${orderId}/forward/${result.id}`);
      await this.fetchOrders();
    },
    async pullBackForword(orderId, comfirmText) {
      if (
        comfirmText != undefined &&
        !(await this.$refs.confirmModal.confirm(comfirmText))
      )
        return;

      await this.service.post(`/orders/${orderId}/Undoforward`);
      await this.fetchOrders();
    },
    async openInfoCard(data) {
      await this.$refs.supplierOrderInfoCardInfo.open(data);
    },
    async openImageModel(order) {
      await this.$refs.orderImageModal.open(order);
    },
  },
  computed: {
    ...mapGetters({
      accountUsers: "accountUsers/list",
    }),
    ordersToShow() {
      if (this.activeCategory == "INBOX") return this.newOrders;
      if (this.activeCategory == "CONFIRMED") return this.confirmedOrders;
      if (this.activeCategory == "CLOSED") return this.emptiedOrders;
    },
    role() {
      return authHelper.getRole();
    },
    newOrders() {
      return this.orders.filter(
        (e) => e.confirmDate.indexOf("0001-01-01T00:00:00") > -1
      );
    },
    confirmedOrders() {
      return this.orders.filter(
        (e) => e.confirmDate.indexOf("0001-01-01T00:00:00") == -1 && !e.emptied
      );
    },
    emptiedOrders() {
      return this.orders.filter(
        (e) => e.confirmDate.indexOf("0001-01-01T00:00:00") == -1 && e.emptied
      );
    },
    tableContent() {
      return {
        fields: [
          {
            key: "containerImg",
            label: "",
            class: "img-td",
          },
          {
            key: "locationName",
            label: this.$t("Collector.Fields.LocationName"),
            sortable: true,
          },
          {
            key: "infoCardName",
            label: this.$t("Collector.Fields.InfocardName"),
            sortable: true,
          },

          {
            key: "customerName",
            label: this.$t("Collector.Fields.CustomerName"),
            sortable: true,
          },
          {
            key: "createdDate",
            label: this.$t("Generic.OrderDate"),
            sortable: true,
          },
          {
            key: "comment",
            label: this.$t("Generic.Comment"),
            class: "table-warning",
          },
          {
            key: "action",
            label: "",
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding-top: 30px;
}
.order-inbox {
  background: #fff;
  border: 1px solid #ebe9f1;
  border-radius: 0.428rem;
  height: calc(100vh - 120px);
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;

  .side-bar {
    width: 260px;
    height: 100%;
    border-right: 1px solid #ebe9f1;
    .title {
      padding: 1.5rem;
      text-align: center;
      border-bottom: 1px solid #ebe9f1;
    }

    .list-group {
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      border-radius: 0.357rem;

      .list-group-item {
        padding: 0.58rem 1.5rem;
        border: 0;
        letter-spacing: 0.4px;
        border-left: 2px solid transparent;
        border-radius: 0;

        &.active {
          background: 0 0;
          color: #407e3d;
          border-color: #407e3d;
          font-weight: 500;
        }

        &:hover {
          color: #407e3d;
          cursor: pointer;
        }
      }
    }
  }
  .orders-list {
    width: calc(100% - 260px);

    @media only screen and (max-width: 648px) {
      width: 100%;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        background-color: #f8f8f8;
        padding: 1.5rem;
        border-bottom: 1px solid #e7e3ed;
        display: flex;
        .left {
          width: 50px;
          position: relative;
          .info-icon {
            background-color: #407e3d;
            border-radius: 50%;
            color: #fff;
            position: absolute;
            bottom: 5px;
            right: 5px;
            cursor: pointer;
          }
        }

        .right {
          display: flex;
          width: 100%;
          .order-details {
            width: 100%;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .order-date {
              color: #b9b9c3;
              font-size: 0.857rem;
            }

            .details-text {
              font-size: 11px;
              display: block;
              margin-bottom: 2px;
            }

            .actions {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 648px) {
    flex-direction: column;

    .side-bar {
      border-right: 0px;
      width: 100%;
      height: 165px;
      border-bottom: 1px solid #ebe9f1;

      .title {
        padding: 10px;
        font-size: 20px;
      }
    }
  }
}
</style>

