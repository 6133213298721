<template>
  <div>
    <b-modal
    id="booststrapmodal"
      :title="T('Web.OrderingPage.OrderImageModal.Title', 'Images')"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="lg"
    >
      <div class="order-list">
        <div v-for="image in images" :key="image.path">
          <span class="icon-container">
            <icon-badge
              @click="removeFile(image.id)"
              v-if="image.userId == accountId"
              icon="XIcon"
              v-b-tooltip.hover.top="T('Web.Generic.Remove')"
            />
          </span>
          <a :href="imageBaseUrl + '/images/' + image.path" target="_blank">
            <img :src="imageBaseUrl + '/images/' + image.path" />
          </a>
        </div>
        <span v-if="images.length == 0">{{
          T("Web.OrderingPage.OrderImageModal.Title", "No images")
        }}</span>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <input type="file" ref="fileupload" @change="setFiles" multiple />
          <b-button
            variant="primary"
            class="float-right"
            :disabled="uploading"
            @click="handleSubmit"
          >
            {{
              uploading
                ? T("Web.Generic.Uploading", 'Uploading..')
                : T("Web.Generic.Upload", "Upload")
            }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleModalHidden"
          >
            {{T("Web.Generic.Close")}}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BAvatar, BTable, BFormTextarea } from "bootstrap-vue";
import FlexTable from "@/components/flex-table/FlexTable.vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
import OrdersService from "@/services/collection/orders-service";
import IconBadge from "@/components/IconBadge.vue";
import { VBTooltip } from "bootstrap-vue";
import authHelper from "@/auth";

export default {
  components: {
    BModal,
    BButton,
    FlexTable,
    BAvatar,
    BTable,
    BFormTextarea,
    WeekdayPicker,
    IconBadge,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    this.service = new OrdersService();
    let userid = authHelper.getAccountId();
    if (userid != undefined) this.accountId = userid;
  },
  data() {
    return {
      imageBaseUrl: process.env.VUE_APP_APIURL,
      service: null,
      uploading: false,
      loading: false,
      order: {},
      images: [],
      imagesToUpload: {
        files: [],
      },
      visible: false,
      accountId: "00000000-0000-0000-0000-000000000000",
    };
  },
  methods: {
    async open(order) {
      this.visible = true;
      this.order = order;
      await this.fetchImage();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async fetchImage() {
      this.loading = true;
      let response = await this.service.getImagesForCollectorOrder(
        this.order.id
      );
      this.images = response.data.data;
      this.loading = false;
    },
    async removeFile(imageId) {
      await this.service.removeImagesForCollectorOrder(this.order.id, imageId);
      await this.fetchImage();
    },
    setFiles(event) {
      this.imagesToUpload.files = event.target.files;     
    },
    handleModalHidden() {
      this.visible = false;
      this.infoCards = [];
    },
    async handleSubmit() {
      this.imagesToUpload.accountId = this.accountId;

      this.uploading = true;
      await this.service.addImagesForCollectorOrder(
        this.order.id,
        this.imagesToUpload
      );
      this.uploading = false;
      this.$refs.fileupload.value = null;
      await this.fetchImage();
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.order-list {
  display: flex;
  max-width: 800px;
  flex-wrap: wrap;
  span.icon-container {
    position: absolute;
    top: 2px;
    right: 2px;
    span {
      &.hover-effect:hover {
        background-color: #cf4330;
      }
    }
  }
  div {
    position: relative;
    width: 180px;
    min-height: 180px;
    margin: 3px 2px;
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
}
</style>