
<template>
  <gmaps-marker
    :key="infocard.id"
    v-if="GMaps"
    :options="markerOptions"
    @mouseover="$emit('mouseover')"
    @mouseout="$emit('mouseout')"
    @click="$emit('click')"
  />
</template>
<script>
import { gmaps, gmapsMarker } from "x5-gmaps";
export default {
  components: { gmapsMarker },
  inject: ["getMap"],
  props: {
    infocard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      GMaps: null,
      map: null,
      visible: false,
      position: null,
    };
  },

  async mounted() {
    this.GMaps = await gmaps();
    this.map = await this.getMap();
    this.position = new this.GMaps.LatLng(
      this.markerOptions.position.lat,
      this.markerOptions.position.lng
    );
    this.map.addListener("bounds_changed", () => this.updateVisibility());
    this.updateVisibility();
  },
  methods: {
    async updateVisibility() {
      this.visible = await this.map.getBounds().contains(this.position);
    },
  },
  computed: {
    markerOptions() {
      return {
        position: {
          lat: Number.parseFloat(this.infocard.latitude),
          lng: Number.parseFloat(this.infocard.longitude),
        },

        icon: {
          path: this.GMaps.SymbolPath.CIRCLE,
          fillColor:
            this.infocard.type == "AccountInfoCard" ? "#82868b" : "#407e3d",
          fillOpacity: 1,
          scale: 6,
          strokeColor:
            this.infocard.type == "AccountInfoCard" ? "#82868b" : "#407e3d",
          strokeWeight: 10,
          strokeOpacity: 0.6,
        },
      };
    },
  },
  watch: {
    visible(newValue) {
      this.$emit("visibility-changed", {
        infocardId: this.infocard.id,
        visible: newValue,
      });
    },
  },
};
</script>