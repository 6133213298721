<template>
  <div class="map-container" @mousedown.stop></div>
</template>

<script>
export default {
  props: {
    lng: {
      required: false,
      default: 0,
    },
    lat: {
      required: false,
      default: 0,
    },
  },
  mounted() {
    let myLatlng = new google.maps.LatLng(this.lat, this.lng);
    // Options
    let mapOptions = {
      zoom: 18,
      center: myLatlng,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: true,
    };
    // Apply options
    let map = new google.maps.Map(this.$el, mapOptions);
        // Add marker
    let marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
    });
    marker.setMap(map);
  },
};
</script>
<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 250px;
}
</style>
